// src/components/Dashboard.js
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const { auth, logout } = useContext(AuthContext);
  const [menu1Message, setMenu1Message] = useState('');
  const [menu2Message, setMenu2Message] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      navigate('/login');
    } else {
      axios.get('https://imoscape.luispedrostudio.com/backend/dashboard/menu1', {
        headers: { Authorization: `Bearer ${auth.accessToken}` }
      }).then(response => {
        setMenu1Message(response.data.message);
      }).catch(error => {
        console.error('Erro ao obter Menu 1:', error);
      });

      axios.get('https://imoscape.luispedrostudio.com/backend/dashboard/menu2', {
        headers: { Authorization: `Bearer ${auth.accessToken}` }
      }).then(response => {
        setMenu2Message(response.data.message);
      }).catch(error => {
        console.error('Erro ao obter Menu 2:', error);
      });
    }
  }, [auth, navigate]);

  return (
    <div>
      <h2>Dashboard</h2>
      <button onClick={logout}>Logout</button>
      <div>
        <h3>Menu 1</h3>
        <p>{menu1Message}</p>
      </div>
      <div>
        <h3>Menu 2</h3>
        <p>{menu2Message}</p>
      </div>
    </div>
  );
};

export default Dashboard;
